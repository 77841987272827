import React, { useState, useEffect, useMemo } from "react"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import SEO from "../../components/SEO/SEO"
import SeeAlso from "../../components/SeeAlso/SeeAlso"

import NavBar from "../../components/NavBar/NavBar"
import { isSSR } from "../../components/Script/script"
import {
  Title,
  SubTitle,
  Columns,
  Column,
  Button,
} from "../../StyleComponents/styles"
import { FlexDiv } from "../../StyleComponents/pagesStyle"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))
// import GoogleAds from "../../components/ADS"

function FlowersGenerator(props) {
  const { node } = props.pageContext
  let [result1, setresult1] = useState([])
  let [hide, setHide] = useState(false)

  useEffect(() => {
    generate()
  }, [])

  const generate = () => {
    randomizer(
      node.num1,
      node.num2,
      node.num3,
      node.num4,
      node.num8,
      node.num6,
      node.num5
    )
  }

  const randomizer = (num1, num2, num3, num4, num8, num6, num5) => {
    let NamesData = []

    for (let i = 0; i < 10; i++) {
      if (i < 5) {
        let random = Math.floor(Math.random() * num1.length)
        let random2 = Math.floor(Math.random() * num2.length)
        NamesData.push(`${num1[random]} ${num2[random2]}`)
      } else {
        let random3 = Math.floor(Math.random() * num3.length)
        let random4 = Math.floor(Math.random() * num4.length)
        if (i < 7) {
          let random8 = Math.floor(Math.random() * num8.length)
          let names = num3[random3] + num4[random4] + num8[random8]
          names = names.charAt(0).toUpperCase() + names.slice(1)
          NamesData.push(names)
        } else {
          let random5 = Math.floor(Math.random() * num5.length)
          if (random3 > 9) {
            while (random5 > 9) {
              random5 = Math.floor(Math.random() * num5.length)
            }
          }
          let random6 = Math.floor(Math.random() * num6.length)
          let names
          let random8 = Math.floor(Math.random() * num8.length)
          names =
            num3[random3] +
            num4[random4] +
            num5[random5] +
            num6[random6] +
            num8[random8]
          names = names.charAt(0).toUpperCase() + names.slice(1)
          NamesData.push(names)
        }
      }
    }

    setresult1(NamesData)
    if (hide === false) {
      setHide(true)
    }
  }

  // const copyName = e => {
  //   console.log(e.target)
  // }
  return (
    <Layout location={props.location}>
      <SEO
        title={`Random ${node.name} Generator`}
        description={`Simply press on the button "generate" to generate a random ${node.name}, wee help you to find the perfect ${node.name}.`}
        keywords={[
          `${node.name},${node.name} generator,plant name generator, random ${node.name} generator, random ${node.name},make ${node.name},generate random ${node.name},random Flowers username`,
        ]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [
            { name: "Generator", link: "/generator/" },
            { name: `${node.name} Generator`, link: `/${node.path}/` },
          ]
        }, [node.name, node.path])}
      />
      <section className="container">
        <Title>{node.name} generator</Title>
        <SubTitle>Random {node.name} Generator</SubTitle>
        <br />
        <Columns>
          <Column>
            <FlexDiv textAlign="center" minHeight="400px">
              <Button
                className="mb-3"
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                fontSize="1.5rem"
                value="Generate"
                onClick={generate}
              >
                Generate
              </Button>
              <br />
              {hide ? (
                <div>
                  {result1.map((e, index) => {
                    return (
                      <p className="h5" key={index}>
                        {e}
                      </p>
                    )
                  })}
                </div>
              ) : (
                ""
              )}{" "}
            </FlexDiv>
          </Column>
          <Column>
            {/* google ads UnitPediaRight */}
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <FlexDiv maxWidth="250px" margin="auto">
          <Img
            fluid={node.img.childImageSharp.fluid}
            alt={`random ${node.name} generator`}
          />
        </FlexDiv>
        <br />
        <div>
          <h3>Random {node.name} Generator</h3>
          <p>
            Simply press on the button "generate" to generate a random{" "}
            {node.name}, wee help you to find the perfect {node.name}
          </p>
        </div>
        <br />
        <SeeAlso seeAlsoList={node.SeeAlso} />
        <br />
      </section>
    </Layout>
  )
}

export default FlowersGenerator
